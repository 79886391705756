.some-skills-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-left: 35px;
	margin-right: 35px;
}
.some-skills-title {
	text-justify: center;
	padding-top: 30px;
	padding-bottom: 30px;
}
.some-skills-skill {
	width: calc(100% / 5);
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
}

@media (max-width: 600px) {
	.some-skills-skill {
		width: calc(100% / 2);
	}
}
