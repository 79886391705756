@import "../../data/styles.css";

.read-story-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.read-story-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.read-story-container {
	display: flex;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.read-story-back {
	padding-top: 68px;
	padding-right: 50px;
	width: 40px;
	height: 40px;
}

.read-story-back-button {
	width: 50px;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.read-story-wrapper {
	width: 80%;
	padding-top: 30px;
}

.read-story-date-container {
	border-left: 2px solid var(--quaternary-color);
	color: var(--tertiary-color);
	font-size: 16 px;
	align-items: center;
	height: 22px;
}

.read-story-date {
	font-family: var(--primary-font);
	padding-left: 15px;
}

.read-story-title {
	padding-top: 50px;
	padding-bottom: 50px;
	width: 100% !important;
}

.read-story-body {
	padding-bottom: 50px;
	font-size: 16px;
	line-height: 30px;
	font-family: system-ui;
	align-self: left;
	color: #52525b;
}

.pdf-container {
	border: 3px solid rgba(0, 0, 0, 0.3);
    height: 750px;
}
