@import "../../../data/styles.css";

.story {
	display: flex;
}

.story a {
	text-decoration: none;
}

.story-left-side {
	min-width: 0%;
	max-width: 0%;
}

.story-right-side {
	flex-basis: 100%;
	min-width: 700px;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	padding: 30px;
}

.story-right-side:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.story-date {
	padding-top: 30px;
	font-size: 15px;
	color: var(--tertiary-color);
}

.story-title {
	color: #000000;
	font-size: 16px;
	font-weight: 600;
}

.story-description {
	padding-top: 10px;
	font-size: 12px;
	color: var(--secondary-color);
	font-weight: 480;
	line-height: 25px;
}

.story-link {
	padding-top: 10px;
	font-size: 14px;
	color: var(--link-color);
	font-weight: 700;
}

@media (max-width: 1024px) {
	.story-left-side {
		min-width: 10%;
		max-width: 10%;
	}

	.story-right-side {
		flex-basis: 90%;
		min-width: 85%;
		max-width: 85%;
	}
}
