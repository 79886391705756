@import "../../../data/styles.css";

.skill {
	align-items: center;
	text-align: center;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
	background: #ABC4C2;
}

.skill a {
	text-decoration: none;
}

.skill:hover {
	background: #8EF9EE;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.skill:hover .skill-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.skill-container {

	padding: 10px;
}

.skill-logo {

	width: 100%;
}

.skill-logo img {
	width: 100%;
}

.skill-image {
	text-align: center;
	justify-self: center;
	align-items: center;
	align-self: 'center'
}

.skill-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.skill-description {
	color: var(--secondary-color);
}

.skill-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.skill-link-icon {
	text-align: center;
	padding-left: 5px;
	font-size: 13px;
}

.skill-link-text {
	padding-left: 20px;
	font-weight: 700;
}

@media (max-width: 600px) {
	.skill {
		height: 300px;
	}
}
