@import "../../data/styles.css";

.read-project-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.read-project-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.read-project-container {
	display: flex;
	height: 100%;
	margin: 0;
	padding-top: 120px;
	justify-content: center;
}

.read-project-back {
	
	padding-top: 68px;
	padding-right: 50px;
	width: 40px;
	height: 40px;
}

.read-project-back-button {
	width: 50px;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.read-project-wrapper {
	width: 80%;
	padding-top: 30px;
}

.read-project-date-container {
	justify-content: center;
	border-left: 0px solid var(--quaternary-color);
	color: var(--tertiary-color);
	font-size: 16 px;
	align-items: center;
	height: 22px;
}
.read-project-skills{
	width: 100%;
	border-radius: 20px;
	background-color: #ABC4C2;
}
.read-project-date {
	justify-content: center;
	font-family: var(--primary-font);
	padding-left: 0px;
}

.read-project-title {
	text-align: center;
	color: #FFFFFF;
	padding-top: 50px;
	padding-bottom: 50px;
	width: 100% !important;
}

.read-project-body {
	padding-top: 50px;
	text-align: center;
	padding-bottom: 50px;
	padding-left:50px;
	padding-right:50px;
	font-size: 24px;
	line-height: 30px;
	font-family: system-ui;
	border-radius: 20px;
	background-color: #ABC4C2;
	
	color: #000000;
}